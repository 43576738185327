import { yupResolver } from '@hookform/resolvers/yup';
import { FaGoogle } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  inMemoryPersistence,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithRedirect,
  getRedirectResult,
  signInWithPopup,
} from 'firebase/auth';
import FormControlField from '../components/FormControlField';
import Button from '../components/Button';
import {
  RootState,
  loginSuccess,
  useLazyGetUserByFirebaseIdQuery,
} from '../store';
import { auth, googleAuth } from '../config/firebase-config';
import config from '../config/config';
import useNavigateWithQuery from '../hooks/useNavigateWithQuery';
import UserDTO from '../models/user';

interface FormData {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Debes ingresar un correo')
    .email('Este correo no está bien'),
  password: yup.string().required('Debes ingresar una contraseña'),
});

const LogIn: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();
  const location = useLocation();
  const [authState, setAuthState] = useState({ token: '', firebaseId: '' });
  const [firebaseAuthLoading, setFirebaseAuthLoading] = useState(false);
  const [getUserByFirebaseId] = useLazyGetUserByFirebaseIdQuery();
  const [user, setUser] = useState<UserDTO | null>(null);

  const { user: stateUser, token: stateToken } = useSelector(
    (s: RootState) => s.auth,
  );
  const [isLoading, setIsLoading] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const form = useForm<FormData>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (user && authState.token) {
      dispatch(
        loginSuccess({
          user: {
            id: user.id,
            username: user.username,
            type: user.type,
            email: user.email,
            user_businesses: user.user_businesses,
            user_events: user.user_events,
          },
          token: authState.token,
        }),
      );
      setIsLoading(false);
      navigate(location.state?.from || '/admin/');
    }
  }, [user, authState]);

  useEffect(() => {
    if (stateToken && stateUser) {
      setIsLoading(false);
      navigate(location.state?.from || '/admin/');
    }
  }, [stateToken, stateUser]);

  const { register, handleSubmit, formState } = form;

  const handleSignInWithGoogle = async () => {
    try {
      setFirebaseAuthLoading(true);
      if (config.env !== 'local') {
        return await signInWithRedirect(auth, googleAuth);
      }
      // local no funciona redirect
      const { user: fbUser } = await signInWithPopup(auth, googleAuth);
      const token = await fbUser.getIdToken();
      const firebaseId = fbUser.uid;

      if (!firebaseId) {
        throw new Error('Failed to get Firebase ID');
      }
      if (!fbUser.email) {
        throw new Error('Google User has no email');
      }
      if (fbUser.uid) {
        const { data: userByFbId } = await getUserByFirebaseId({ firebaseId: fbUser.uid });
        setUser(userByFbId ?? null);
      }

      return setAuthState({ token, firebaseId });
    } catch (e) {
      console.error(e);
      return setFirebaseAuthLoading(false);
    }
  };

  useEffect(() => {
    const checkRedirectResult = async () => {
      try {
        setFirebaseAuthLoading(true);
        const result = await getRedirectResult(auth);
        if (result?.user) {
          const fbUser = result.user;
          const token = await fbUser.getIdToken();
          const firebaseId = fbUser.uid;

          if (!firebaseId) {
            throw new Error('Failed to get Firebase ID');
          }
          if (!fbUser.email) {
            throw new Error('Google User has no email');
          }

          setAuthState({ token, firebaseId });
        }
      } catch (e) {
        console.error('Redirect error:', e);
      } finally {
        setFirebaseAuthLoading(false);
      }
    };

    checkRedirectResult();
  }, []);

  const handleLogIn = async (formData: FormData) => {
    setFirebaseAuthLoading(true);
    setErrorMsg('');
    setIsLoading(true);

    try {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch (storageError) {
        try {
          await setPersistence(auth, browserSessionPersistence);
        } catch (sessionError) {
          await setPersistence(auth, inMemoryPersistence);
          console.warn('Using in-memory persistence - user will need to login again after closing the app');
        }
      }

      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email,
        formData.password,
      );
      const token = await userCredential.user.getIdToken();
      const firebaseId = userCredential.user.uid;

      if (!firebaseId) {
        throw new Error('Failed to get Firebase ID');
      }
      setAuthState({ token, firebaseId });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error('Login error:', e);
      if (e.code === 'auth/invalid-credential') {
        setErrorMsg('Usuario o contraseña incorrectos');
      } else {
        setErrorMsg(`Ocurrió un error: ${e.message}`);
      }
    } finally {
      setIsLoading(false);
      setFirebaseAuthLoading(false);
    }
  };

  return (
    <div className="admin px-4">
      <div className="flex flex-col items-center py-10">
        <div className="h-12 my-5">
          <img src="/logo59x61.png" />
        </div>
        <h1 className="text-2xl mb-5">Iniciar sesión</h1>
        <p>Ingresa tus datos para acceder al sistema</p>
      </div>
      <form className="space-y-2" onSubmit={handleSubmit(handleLogIn)}>
        <FormControlField
          label="Usuario"
          labelAbove={true}
          id="email"
          {...register('email', {
            required: true,
          })}
          errMsg={formState.errors.email?.message}
        />
        <FormControlField
          label="Contraseña"
          labelAbove={true}
          id="password"
          type="password"
          {...register('password', {
            required: true,
          })}
          errMsg={formState.errors.password?.message}
        />
        {errorMsg && <p className="text-red-500">{errorMsg}</p>}
        <div className="flex justify-center mt-10">
          <Button
            loading={isLoading}
            className="text-lg"
            disabled={!formState.isValid}
            type="submit"
            primary
          >
            Ingresar
          </Button>
        </div>
      </form>
      <div className='text-center my-5 text-xl'>o</div>
      <div className="flex justify-center align-items-center">
        <Button
          loading={firebaseAuthLoading}
          disabled={firebaseAuthLoading}
          className="text-lg"
          type="button"
          onClick={handleSignInWithGoogle}
          secondary
        >
          <div className="flex items-center align-items-center">
            <FaGoogle className='mr-2' />
            <span>Ingresar con Google</span>
          </div>
        </Button>
      </div>
    </div>
  );
};
export default LogIn;
