/* eslint-disable no-nested-ternary */
import { FaLink } from 'react-icons/fa';
import { Fragment, useEffect, useState } from 'react';
import OrdersTableSkeleton from '../components/OrdersTableSkeleton';
import Button from '../components/Button';
import IconInfoCard from '../components/IconInfoCard';
import CustomModal from '../components/CustomModal';
import useGetParams from '../hooks/useGetParams';
import { useGetAllReferralsQuery } from '../store';
import ReferralsTable from '../components/ReferralsTable';
import CreateEditReferral from '../components/CreateEditReferral';

const AdminReferralsPage: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const { eventId, businessId } = useGetParams();

  const { data: referrals, isFetching } = useGetAllReferralsQuery({
    businessId,
    eventId,
  });

  const handleSucessAddReferral = () => {
    setShowModal(false);
  };

  const handleAddReferral = () => {
    setShowModal(true);
  };

  useEffect(() => {
    console.log({ referrals });
  }, []);

  return (
    <div className="px-3 h-screen">
      <h1 className="text-xl font-medium mb-4">Configuración de referidos</h1>

      <div className="card border border-gray-200 overflow-x-auto">
        <div className="flex py-2 justify-between">
          <h1 className="text-lg">Referidos</h1>
          <Button onClick={handleAddReferral} secondary>
            {' '}
            +{' '}
          </Button>
        </div>
        {isFetching && <OrdersTableSkeleton />}{' '}
        {referrals && referrals.length > 0 && (
          <ReferralsTable referrals={referrals} eventId={+eventId} businessId={+businessId} />
        )}
        {referrals && referrals.length === 0 && (
          <IconInfoCard
            title="Aún no hay referidos creados"
            info="Aquí aparecerán todos los referidos que crees en el sistema"
            icon={FaLink}
            iconColor="text-indigo-600"
          />
        )}
      </div>
      <CustomModal
        onRequestClose={() => setShowModal(false)}
        isOpen={showModal}
        actionBar={<div></div>}
      >
        <Fragment>
          <div className="flex flex-col justify-center items-center mb-5">
            <h1 className="text-xl font-medium mb-4">Crear referido</h1>
            <p className="text-gray-400 text-sm text-center">
              Podrás realizar las ediciones que necesites en este referido
            </p>
          </div>
          <CreateEditReferral onSuccess={handleSucessAddReferral} />
        </Fragment>
      </CustomModal>
    </div>
  );
};

export default AdminReferralsPage;
