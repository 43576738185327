import { FaMoneyBill } from 'react-icons/fa';
import { IoTicketOutline } from 'react-icons/io5';
import toast from 'react-hot-toast';
import { formatCurrency } from '../utils/shared';
import IconFieldValue from './IconFieldValue';
import TextTitleCard from './TitleTextCard';
import { ReferralDTO } from '../types/Referral';
import Button from './Button';
import config from '../config/config';

interface Props {
  referral: ReferralDTO;
}

const ReferralSummary: React.FC<Props> = ({ referral }) => {
  const handleCopyReferralLink = () => {
    navigator.clipboard
      .writeText(
        `${config.baseDomain}/events/${referral.event_id}?ref=${referral.code}`,
      )
      .then(() => {
        toast.success('Link copiado al portapapeles');
      })
      .catch((err) => {
        console.error('Error al copiar al portapapeles: ', err);
      });
  };
  return (
    <TextTitleCard title="Ventas por link">
      <IconFieldValue
        icon={IoTicketOutline}
        field="Entradas vendidas"
        value={referral.tickets_sold}
      />
      <IconFieldValue
        icon={FaMoneyBill}
        field="Total vendido"
        value={formatCurrency(referral.amount_sold)}
      />
      <div className="flex justify-center align-items-center mt-2">
        <Button primary onClick={handleCopyReferralLink}>
          Copiar link
        </Button>
      </div>
    </TextTitleCard>
  );
};

export default ReferralSummary;
