import React from 'react';
import { BsPersonCheckFill } from 'react-icons/bs';
import Button from './Button';
import { DecodedItemData, DecodedSellerQRData } from '../store/apis/orders';
import { parseFriendlyDateAndHour } from '../utils/dates';

type ScanResultType =
  | 'success_confirmed'
  | 'success_unconfirmed'
  | 'error'
  | 'used'
  | 'seller_qr_unconfirmed'
  | 'seller_qr_confirmed';

interface ScanResultCardProps {
  type: ScanResultType;
  qrData: DecodedItemData | DecodedSellerQRData | null;
  onRetry: () => void;
  onConfirm?: () => void;
  onConfirmSellerQR?: () => void;
}

const QrResponse: React.FC<ScanResultCardProps> = ({
  type,
  qrData,
  onRetry,
  onConfirm,
}) => {
  const config = {
    success_confirmed: {
      bgColor: 'bg-green-100',
      iconBg: 'bg-green-200',
      iconColor: 'text-green-600',
      title: '¡Escaneo exitoso!',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      ),
    },
    seller_qr_confirmed: {
      bgColor: 'bg-blue-100',
      iconBg: 'bg-blue-200',
      iconColor: 'text-blue-600',
      title: 'Código RR.PP confirmado',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      ),
    },
    success_unconfirmed: {
      bgColor: 'bg-gray-50',
      iconBg: 'bg-gray-200',
      iconColor: 'text-gray-600',
      title: 'Código escaneado',
      icon: <BsPersonCheckFill className="w-20 h-20" />,
    },
    seller_qr_unconfirmed: {
      bgColor: 'bg-gray-50',
      iconBg: 'bg-gray-200',
      iconColor: 'text-gray-600',
      title: 'Código RR.PP escaneado',
      icon: <BsPersonCheckFill className="w-20 h-20" />,
    },
    error: {
      bgColor: 'bg-red-100',
      iconBg: 'bg-red-200',
      iconColor: 'text-red-600',
      title: '¡Escaneo erróneo!',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      ),
    },
    used: {
      bgColor: 'bg-yellow-100',
      iconBg: 'bg-yellow-200',
      iconColor: 'text-yellow-600',
      title: '¡QR usado!',
      icon: (
        <svg
          className="w-20 h-20"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
      ),
    },
  };

  const {
    bgColor, iconBg, iconColor, title, icon,
  } = config[type];

  return (
    <div className={`${bgColor} h-full p-6 w-full`}>
      <div>
        <div className="flex justify-center mb-4">
          <div className={`${iconBg} mt-10 rounded-full p-8`}>
            <div className={iconColor}>{icon}</div>
          </div>
        </div>
        <h2 className={'text-center text-3xl font-medium mb-20'}>{title}</h2>
        {type === 'success_unconfirmed'
          && qrData
          && 'order_status' in qrData && (
            <>
              <p className="text-center text-xl text-gray-600 mb-2">
                {qrData?.ticket?.name}
              </p>
              <p className="text-center text-4xl font-medium text-gray-600">
                {`${qrData.name} ${qrData.last_name}`.toUpperCase()}
              </p>
              <p className="text-center text-4xl font-medium text-gray-600">
                {qrData.person_id}
              </p>
              <Button
                onClick={onConfirm}
                className={
                  'text-lg mt-20 h-14 w-full bg-green-200 py-2 px-4 border border-gray-200 rounded shadow hover:bg-opacity-50 transition duration-200'
                }
              >
                Aceptar
              </Button>
              <div className="flex items-center justify-center">
                <Button transparent className="text-lg h-14" onClick={onRetry}>
                  Cancelar
                </Button>
              </div>
            </>
        )}
        {type === 'seller_qr_unconfirmed'
          && qrData
          && 'user_event' in qrData && (
            <>
              <p className="text-center text-xl text-gray-600 mb-2">
                Código Promocional RR.PP
              </p>
              <p className="text-center text-4xl font-medium text-gray-600">
                {`${qrData.user_event.user.username}`.toUpperCase()}
              </p>
              <Button
                onClick={onConfirm}
                className={
                  'text-lg mt-20 h-14 w-full bg-blue-200 py-2 px-4 border border-gray-200 rounded shadow hover:bg-opacity-50 transition duration-200'
                }
              >
                Aceptar
              </Button>
              <div className="flex items-center justify-center">
                <Button transparent className="text-lg h-14" onClick={onRetry}>
                  Cancelar
                </Button>
              </div>
            </>
        )}
        {type === 'seller_qr_confirmed' && qrData && 'user_event' in qrData && (
          <>
            <p className="text-center text-xl text-gray-600 mb-2">
              Código Promocional RR.PP confirmado
            </p>
            <p className="text-center text-4xl font-medium text-gray-600">
              {`${qrData.user_event.user.username}`.toUpperCase()}
            </p>
          </>
        )}
        {type === 'success_confirmed' && qrData && 'order_status' in qrData && (
          <>
            <p className="text-center text-xl text-gray-600 mb-2">
              {qrData?.ticket?.name}
            </p>
            <p className="text-center text-4xl font-medium text-gray-600">
              {`${qrData.name} ${qrData.last_name}`.toUpperCase()}
            </p>
            <p className="text-center text-4xl font-medium text-gray-600">
              {qrData.person_id}
            </p>
          </>
        )}
        {type === 'error' && (
          <p className="text-center text-2xl text-gray-600 mb-4 font-medium">
            No se pudo leer el código solicitado
          </p>
        )}
        {type === 'used' && qrData && 'used_at' in qrData && (
          <p className="text-center text-2xl text-gray-600 mb-4 font-medium">
            El código ya fue previamente escaneado:{' '}
            {parseFriendlyDateAndHour(qrData.used_at)}hs.
          </p>
        )}
        {(!type.includes('success') && !type.includes('seller_qr')) && (
          <Button
            onClick={onRetry}
            className={
              'text-lg mt-28 h-14 w-full bg-white font-semibold py-2 px-4 border border-gray-200 rounded shadow hover:bg-opacity-50 transition duration-200'
            }
          >
            Reintentar
          </Button>
        )}
      </div>
    </div>
  );
};

export default QrResponse;
