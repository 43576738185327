import React from 'react';

const OrderTotalSkeleton: React.FC = () => (
    <div className="flex flex-col px-3 my-3 animate-pulse">
      {/* Service charge skeleton */}
      <div className="h-5 bg-gray-200 rounded w-48 mb-1 ml-1" />

      {/* Payment method discount skeleton */}
      <div className="h-5 bg-gray-200 rounded w-64 mb-1 ml-1" />

      {/* Subtotal skeleton */}
      <div className="h-5 bg-gray-200 rounded w-56 mb-1 ml-1" />

      {/* Total skeleton - slightly larger */}
      <div className="h-6 bg-gray-200 rounded w-48 mt-3 mb-4 ml-1" />
    </div>
);

export default OrderTotalSkeleton;
