import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { formatCurrency } from '../utils/shared';
import { OrderData, useGetOrderTotalsQuery } from '../store/apis/orders';
import EventDTO from '../models/event';
import OrderTotalSkeleton from './OrderTotalSkeleton';
import TitleTextCard from './TitleTextCard';

interface OrderTotalProps {
  event: EventDTO;
  order: OrderData;
  onCalculatedTotalToPay: (total: number) => void;
  onLoadingTotals: (loading: boolean) => void;
  hasOpenPriceTicket?: boolean;
}

const OrderTotal: React.FC<OrderTotalProps> = ({
  event,
  order,
  onCalculatedTotalToPay,
  onLoadingTotals,
  hasOpenPriceTicket,
}) => {
  const { data: orderTotals, isFetching } = useGetOrderTotalsQuery({
    businessId: event.business_id,
    eventId: event.id,
    order,
  }, { skip: hasOpenPriceTicket && !order.open_price_amount });

  const location = useLocation();
  const isBackoffice = location.pathname.includes('/admin/');

  useEffect(() => {
    if (orderTotals) {
      onCalculatedTotalToPay(orderTotals.total_amount);
    }
  }, [orderTotals]);

  useEffect(() => {
    onLoadingTotals(isFetching);
  }, [isFetching]);

  return (
    <div className="my-3">
      {orderTotals && !isFetching && (
        <TitleTextCard title="Detalle de pago">
          {isFetching && <OrderTotalSkeleton />}
          {(!isFetching && !isBackoffice) && (
            <div className="flex flex-col">
              <span className="text-sm mb-1 ml-1 font-medium">
                Subtotal:{' '}
                <span
                  className={`${
                    orderTotals.subtotal !== orderTotals.base_tickets_total ? 'line-through font-light' : ''
                  }`}
                >
                  {formatCurrency(orderTotals.base_tickets_total)}
                </span>{' '}
                {(orderTotals.subtotal !== orderTotals.base_tickets_total)
                  && formatCurrency(orderTotals.subtotal)}
              </span>
              {!event.tickets_includes_service_charge && (
                <span className="text-sm mb-1 ml-1 font-medium">
                  Service charge:{' '}
                  {formatCurrency(orderTotals.service_charge_fee)}
                </span>
              )}
              {(event.tickets_includes_service_charge && orderTotals.payment_method_discounts > 0) && (
                <span className="text-sm mb-1 ml-1 font-medium">
                  Descuento por medio de pago:{' '}
                  {formatCurrency(orderTotals.payment_method_discounts)}
                </span>
              )}
              <span className="text-lg mt-3 font-medium mb-4">
                Total a pagar: {formatCurrency(orderTotals.total_amount)}
              </span>
            </div>
          )}
          {(!isFetching && isBackoffice) && (
            <div className="flex flex-col">
              <span className="text-lg mt-3 font-medium mb-4">
                Total a pagar: {formatCurrency(orderTotals.total_amount)}
              </span>
            </div>
          )}
        </TitleTextCard>
      )}
    </div>
  );
};

export default OrderTotal;
