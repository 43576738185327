import { OrderDTO, ItemDTO } from '../models/order';
import OrderItem from '../types/OrderItem';

const mapOrderToDisplayItems = (order: OrderDTO, item: ItemDTO): OrderItem => ({
  id: order.id,
  name: `${item.name} ${item.last_name}`,
  person_id: item.person_id,
  order_status: order.status,
  amount: item.ticket?.price,
  item_id: item.id,
  first_name: item.name,
  last_name: item.last_name,
  email: order.email,
  order_id: order.id,
  is_manual: order.is_manual,
  created_at: order.created_at,
  order_display_id: order.display_id,
  ticket_name: item.ticket?.name,
  ticket_is_extra: item.ticket?.is_extra,
  order_custom_id: order.custom_id,
});

export default mapOrderToDisplayItems;
