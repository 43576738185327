import { Fragment, useEffect, useState } from 'react';
import { PiWarningCircleLight } from 'react-icons/pi';
import { FiEdit } from 'react-icons/fi';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import config from '../config/config';
import OrderItem from '../types/OrderItem';
import SortableTable from './SortableTable';
import MenuDropdown from './MenuDropdown';
import CustomModal from './CustomModal';
import {
  RootState,
  useDeleteOrderItemMutation,
  useSendOrderEmailMutation,
} from '../store';
import Button from './Button';
import IconInfoCard from './IconInfoCard';
import EditOrderForm from './EditOrderForm';
import useGetParams from '../hooks/useGetParams';

interface Props {
  orderItems: OrderItem[];
}

const ApprovedOrdersTable: React.FC<Props> = ({ orderItems }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(<div></div>);
  const [actionBar, setActionBar] = useState(<div></div>);
  const { token } = useSelector((state: RootState) => state.auth);
  const [sortedOrderItems, setSorterOrderItems] = useState<OrderItem[]>([]);
  const { eventId, businessId } = useGetParams();

  const [deleteOrderItem, resultsDelete] = useDeleteOrderItemMutation();
  const [sendEmail] = useSendOrderEmailMutation();

  useEffect(() => {
    setSorterOrderItems([...orderItems].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()));
  }, [orderItems]);

  const handleConfirmDeleteOrder = (item: OrderItem) => {
    toast.promise(
      deleteOrderItem({
        businessId,
        eventId: +eventId,
        orderId: item.id,
        itemId: item.item_id,
      }).unwrap(),
      {
        loading: 'Eliminando...',
        success: <b>Entrada eliminada con éxito</b>,
        error: <b>Error al eliminar orden.</b>,
      },
    );
    setShowModal(false);
  };

  const handleClose = () => setShowModal(false);

  const handleDeleteOrder = (item: OrderItem) => {
    setModalBody(
      <IconInfoCard
        iconColor="text-red-600"
        icon={PiWarningCircleLight}
        title="¿Eliminar entrada?"
        info="Al eliminar entrada el ticket quedará inhabilitado y no podrá ser utilizado."
      />,
    );
    setActionBar(
      <div className="w-full">
        <Button
          danger
          onClick={() => handleConfirmDeleteOrder(item)}
          className="text-center w-full mt-2"
        >
          Eliminar
        </Button>
      </div>,
    );
    setShowModal(true);
  };

  const handleEditItem = (item: OrderItem) => {
    setModalBody(
      <Fragment>
        <IconInfoCard
          title="Editar datos"
          info="En los siguientes campos podrás editar los datos que necesites"
          icon={FiEdit}
          iconColor="text-indigo-600"
        />
        <EditOrderForm order={item} onSuccess={handleClose} />
      </Fragment>,
    );
    setActionBar(<div></div>);
    setShowModal(true);
  };

  const resendEmail = (item: OrderItem) => {
    toast.promise(
      sendEmail({
        businessId,
        eventId: +eventId,
        customId: item.order_custom_id,
      }).unwrap(),
      {
        loading: 'Enviando correo...',
        success: <b>Correo enviado con éxito</b>,
        error: <b>Error al enviar correo.</b>,
      },
    );
  };

  const downloadQr = (item: OrderItem) => {
    toast.promise(
      axios
        .get(
          `${config.baseURL}/v1/businesses/${businessId}/events/${eventId}/orders/${item.order_id}/items/${item.item_id}/qr-pdf`,
          {
            responseType: 'blob',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `QR_Ticket_${item.last_name}_${item.first_name}.pdf`,
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          console.error(e);
          throw e;
        }),
      {
        loading: 'Obteniendo PDF...',
        success: <b>Descargando PDF</b>,
        error: <b>Error al obtener PDF</b>,
      },
    );
  };

  const dropDownOptions = [
    {
      label: 'Editar',
      onClick: handleEditItem,
    },
    {
      label: 'Descargar QR',
      onClick: downloadQr,
    },
    {
      label: 'Reenviar correo',
      onClick: resendEmail,
    },
    {
      label: 'Eliminar',
      onClick: handleDeleteOrder,
    },
  ];

  const tableConfig = [
    {
      label: 'ID',
      render: (item: OrderItem) => item.order_display_id,
    },
    {
      label: 'Nombre',
      render: (item: OrderItem) => item.name,
      sortValue: (item: OrderItem) => item.name,
    },
    {
      label: 'DNI',
      render: (item: OrderItem) => item.person_id,
      sortValue: (item: OrderItem) => item.person_id,
    },
    {
      label: '',
      render: (item: OrderItem) => (
        <div>
          <MenuDropdown<OrderItem>
            options={dropDownOptions}
            data={item}
            loading={resultsDelete.isLoading}
          />
        </div>
      ),
    },
  ];

  const keyFn = (item: OrderItem) => item.item_id;

  return (
    <div>
      <SortableTable
        data={sortedOrderItems}
        config={tableConfig}
        keyFn={keyFn}
        pagination={true}
        search={true}
      />
      <CustomModal
        actionBar={actionBar}
        onRequestClose={handleClose}
        isOpen={showModal}
      >
        {modalBody}
      </CustomModal>
    </div>
  );
};

export default ApprovedOrdersTable;
