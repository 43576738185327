interface LoadingTextProps {
  text: string | number;
  className?: string;
  isLoading?: boolean;
}

const LoadingText: React.FC<LoadingTextProps> = ({
  text,
  className = '',
  isLoading = false,
}) => {
  if (isLoading) {
    return (
      <div
        className={`animate-pulse bg-gray-200 rounded ${className}`}
        style={{ minWidth: '2rem' }}
      >
        &nbsp;
      </div>
    );
  }

  return <span className={className}>{text}</span>;
};

export default LoadingText;
