import { useState } from 'react';
import toast from 'react-hot-toast';
import useGetParams from '../hooks/useGetParams';
import { useUpdateUserEventMutation } from '../store';
import { ReferralDTO } from '../types/Referral';
import Select from './Select';
import Button from './Button';
import { UserWithBusinessAndEvent } from '../models/user';

interface Props {
  user: UserWithBusinessAndEvent;
  availableReferrals: ReferralDTO[];
  onClose: (referralId: number) => void;
}

const LinkReferralToSeller: React.FC<Props> = ({
  user,
  availableReferrals,
  onClose,
}) => {
  const [selectedReferral, setSelectedReferral] = useState<number | null>(null);
  const { businessId, eventId } = useGetParams();
  const [updateUserEvent, results] = useUpdateUserEventMutation();
  const handleLinkReferral = () => {
    if (!selectedReferral) {
      toast.error('Selecciona un link refererido');
      return;
    }
    updateUserEvent({
      businessId,
      eventId,
      data: { id: user.user_event.id, referral_id: selectedReferral },
    });
    onClose(selectedReferral);
  };
  return (
    <div>
      Referido Actual: {user.user_event.referral_id}
      <Select
        id="referral"
        label="Seleccionar link refererido"
        options={availableReferrals.map((referral) => ({
          label: `${referral.name} (${referral.id})`,
          value: referral.id,
        }))}
        onChange={(e) => setSelectedReferral(+e.target.value)}
      />
      <Button
        loading={results.isLoading}
        primary
        onClick={() => handleLinkReferral()}
      >
        Asignar
      </Button>
    </div>
  );
};

export default LinkReferralToSeller;
