import React, { useState, useRef, useEffect } from 'react';
import { GoSync } from 'react-icons/go';

interface Option<T> {
  hidden?: boolean;
  label: string;
  onClick: (data: T) => void;
}

interface DropdownProps<T> {
  data: T;
  options: Option<T>[];
  loading?: boolean;
  fixed?: boolean;
}
function MenuDropdown<T>({
  data, options, loading, fixed,
}: DropdownProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current
      && !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative inline-block">
      {loading ? (
        <GoSync className="animate-spin" />
      ) : (
        <button
          className="text-2xl cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          &#8942;
        </button>
      )}
      {isOpen && (
        <div className={`${fixed ? 'fixed' : 'absolute'} z-40 right-0 mt-2 w-32 text-sm bg-white border border-gray-300 rounded shadow-lg`}>
          <ul className="py-1">
            {options.map((option, index) => (
              !option.hidden && (
                <li key={index}>
                  <a
                    className="block px-3 py-1 hover:bg-gray-200"
                  onClick={() => {
                    setIsOpen(false);
                    option.onClick(data);
                  }}
                >
                  {option.label}
                  </a>
                </li>
              )
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default MenuDropdown;
