import { createApi } from '@reduxjs/toolkit/query/react';
import { Response } from '../../models/shared';
import { BusinessDTO } from '../../models/business';
import baseQueryWithReauth from './base';
import UserDTO from '../../models/user';
import InvitationDTO from '../../models/invitation';

const businessesApi = createApi({
  reducerPath: 'businessesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['businesses', 'invitations'],
  endpoints: (build) => ({
    getBusinessById: build.query<BusinessDTO | null, number>({
      providesTags: (result, error, id) => [{ type: 'businesses', id }],
      query: (id) => ({
        url: `/businesses/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<BusinessDTO>): (BusinessDTO | null) => response.data || null,
    }),
    getBusinessBySubdomain: build.query<BusinessDTO | null, string>({
      query: (subdomain) => ({
        url: `/businesses/${subdomain}?nickname=true`,
        method: 'GET',
      }),
      transformResponse: (response: Response<BusinessDTO>): (BusinessDTO | null) => response.data || null,
    }),
    getAllBusinesses: build.query<BusinessDTO[] | null, { query?: Partial<BusinessDTO> }>({
      providesTags: () => [{ type: 'businesses' }],
      query: ({ query }) => ({
        url: '/businesses',
        params: query,
        method: 'GET',
      }),
      transformResponse: (response: Response<BusinessDTO[]>): (BusinessDTO[] | null) => response.data || null,
    }),
    createBusiness: build.mutation<Response<BusinessDTO>, { business: Partial<BusinessDTO> }>({
      query: ({
        business,
      }) => ({
        url: '/businesses',
        method: 'POST',
        body: business,
      }),
      invalidatesTags: ['businesses'],
    }),
    updateBusiness: build.mutation<Response<BusinessDTO>, { business: Partial<BusinessDTO> }>({
      query: ({
        business,
      }) => ({
        url: '/businesses',
        method: 'PUT',
        body: business,
      }),
      invalidatesTags: ['businesses'],
    }),
    getBusinessUsers: build.query<UserDTO[] | null, { businessId: number }>({
      providesTags: () => [{ type: 'businesses' }],
      query: ({ businessId }) => ({
        url: `/businesses/${businessId}/users`,
        method: 'GET',
      }),
      transformResponse: (response: Response<UserDTO[]>): (UserDTO[] | null) => response.data || null,
    }),
    getBusinessInvitations: build.query<InvitationDTO[] | null, { businessId: number }>({
      providesTags: () => [{ type: 'invitations' }],
      query: ({ businessId }) => ({
        url: `/businesses/${businessId}/invitations`,
        method: 'GET',
      }),
      transformResponse: (response: Response<InvitationDTO[]>): (InvitationDTO[] | null) => response.data || null,
    }),
    createInvitation: build.mutation<Response<InvitationDTO>, { businessId: number, email: string, permissions: string }>({
      query: ({
        businessId, email, permissions,
      }) => ({
        url: `/businesses/${businessId}/invitations`,
        method: 'POST',
        body: { email, permissions },
      }),
      invalidatesTags: ['invitations'],
    }),
    acceptInvitation: build.mutation<Response<InvitationDTO>, { businessId: number, userId: number, token: string }>({
      query: ({
        businessId, userId, token,
      }) => ({
        url: `/businesses/${businessId}/invitations/accept`,
        method: 'POST',
        body: { user_id: userId, token },
      }),
      invalidatesTags: ['invitations'],
    }),
    removeUserFromBusiness: build.mutation<Response<InvitationDTO>, { userBusinessId: number, businessId: number }>({
      query: ({ userBusinessId, businessId }) => ({
        url: `/businesses/${businessId}/user-business/${userBusinessId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['businesses'],
    }),
    deleteInvitation: build.mutation<Response<InvitationDTO>, { businessId: number, invitationId: number }>({
      query: ({ businessId, invitationId }) => ({
        url: `/businesses/${businessId}/invitations/${invitationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['invitations'],
    }),
    getEmailInvitations: build.query<InvitationDTO[] | null, { email: string }>({
      providesTags: () => [{ type: 'invitations' }],
      query: ({ email }) => ({
        url: `/invitations/email/${email}`,
        method: 'GET',
      }),
      transformResponse: (response: Response<InvitationDTO[]>): (InvitationDTO[] | null) => response.data || null,
    }),
  }),
});

export const {
  useGetBusinessByIdQuery,
  useGetAllBusinessesQuery,
  useCreateBusinessMutation,
  useUpdateBusinessMutation,
  useGetBusinessUsersQuery,
  useGetBusinessInvitationsQuery,
  useCreateInvitationMutation,
  useGetEmailInvitationsQuery,
  useAcceptInvitationMutation,
  useGetBusinessBySubdomainQuery,
  useDeleteInvitationMutation,
  useRemoveUserFromBusinessMutation,
} = businessesApi;
export { businessesApi };
