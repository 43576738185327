// import { IoTicketOutline } from 'react-icons/io5';
import { FaMoneyBill } from 'react-icons/fa';
import IconFieldValue from '../components/IconFieldValue';
import LoadingLottie from '../components/LoadingLottie';
import TextTitleCard from '../components/TitleTextCard';
import useGetParams from '../hooks/useGetParams';
import useGetSelectedEvent from '../hooks/useGetSelectedEvent';
import { useGetEventSalesReportQuery } from '../store';
import { formatCurrency } from '../utils/shared';

const EventSalesReportPage: React.FC = () => {
  const { eventId, businessId } = useGetParams();
  const { event } = useGetSelectedEvent();
  const { data: reportData, isLoading } = useGetEventSalesReportQuery({
    businessId,
    eventId,
  });
  if (isLoading) {
    return <LoadingLottie />;
  }
  if (!reportData) {
    return <div>No hay datos</div>;
  }
  return (
    <div>
      <h1>Reporte de ventas: {event?.name}</h1>
      <div className="mb-3">
        <TextTitleCard title='Resumen general de ventas'>
          {/* <IconFieldValue icon={IoTicketOutline} field="Entradas vendidas" value={`${reportData.total_count}`} /> */}
          <IconFieldValue icon={FaMoneyBill} field="Total vendido" value={formatCurrency(reportData.total_amount)} />
        </TextTitleCard>
      </div>
      <div className="mb-3">
        <TextTitleCard title='Resumen de ventas online'>
          {/* <IconFieldValue icon={IoTicketOutline} field="Entradas vendidas" value={`${reportData.count_online}`} /> */}
          <IconFieldValue icon={FaMoneyBill} field="Total vendido" value={formatCurrency(reportData.total_amount_online)} />
        </TextTitleCard>
      </div>
      <div className="mb-3">
        <TextTitleCard title='Resumen de ventas por sistema'>
          {/* <IconFieldValue icon={IoTicketOutline} field="Entradas vendidas" value={`${reportData.count_manual}`} /> */}
          <IconFieldValue icon={FaMoneyBill} field="Total vendido" value={formatCurrency(reportData.total_amount_manual)} />
        </TextTitleCard>
      </div>
    </div>
  );
};

export default EventSalesReportPage;
