import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import SortableTable from './SortableTable';
import UserDTO, { displayUserPermissions, UserBusiness } from '../models/user';
import MenuDropdown from './MenuDropdown';
import ConfirmActionModal from './ConfirmActionModal';
import { useRemoveUserFromBusinessMutation } from '../store';

interface Props {
  users: UserDTO[];
  businessId: number;
}

type ParsedUser = UserDTO & UserBusiness;

const parseUser = (businessId: number, user: UserDTO): ParsedUser => {
  const userBiz = user.user_businesses.find(
    (ub) => ub.business_id === businessId,
  );
  if (!userBiz) {
    throw new Error('no user business found');
  }
  return {
    ...user,
    ...userBiz,
  };
};

const UsersTable: React.FC<Props> = ({ users, businessId }) => {
  const [parsedUsers, setParsedUsers] = useState<ParsedUser[]>([]);
  const [removeUserFromBusiness, { isLoading: isRemovingUser }] = useRemoveUserFromBusinessMutation();
  const [showModal, setShowModal] = useState(false);
  const [userBusinessId, setUserBusinessId] = useState<number | null>(null);

  const onDeleteUser = (id: number) => {
    setUserBusinessId(id);
    setShowModal(true);
  };

  const handleConfirmRemoveUser = () => {
    if (userBusinessId) {
      toast.promise(removeUserFromBusiness({ userBusinessId, businessId }).unwrap(), {
        loading: 'Eliminando usuario...',
        success: 'Usuario eliminado correctamente',
        error: 'Error al eliminar usuario',
      });
      setShowModal(false);
    }
  };

  const dropDownOptions = [
    {
      label: 'Eliminar',
      onClick: (userBusiness: UserBusiness) => onDeleteUser(userBusiness.id),
    },
  ];

  useEffect(() => {
    setParsedUsers(users.map((u) => parseUser(businessId, u)));
  }, [users]);

  const config = [
    {
      label: 'Usuario',
      render: (user: ParsedUser) => user.username,
    },
    {
      label: 'Permisos',
      render: (user: ParsedUser) => displayUserPermissions[
        user.permissions as keyof typeof displayUserPermissions
      ],
    },
    {
      label: 'Activo',
      render: (user: ParsedUser) => (
        <div className="flex justify-center items-center">
          {' '}
          {user.active ? (
            <AiFillCheckCircle className=" text-green-600" />
          ) : (
            <AiFillCloseCircle className="text-red-600" />
          )}
        </div>
      ),
    },
    {
      label: '',
      render: (user: ParsedUser) => (
        <div>
          <MenuDropdown<ParsedUser>
            options={dropDownOptions}
            data={user}
            loading={isRemovingUser}
          />
        </div>
      ),
    },
  ];

  const keyFn = (user: UserDTO) => `${user.id}`;

  return (
    <div>
      <SortableTable
        data={parsedUsers}
        config={config}
        keyFn={keyFn}
        pagination={false}
        search={false}
      />
      <ConfirmActionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmRemoveUser}
        title="¿Estás seguro de querer eliminar este usuario del evento?"
        message="Una vez eliminado, tendrás que volver a invitarlo."
      />
    </div>
  );
};

export default UsersTable;
